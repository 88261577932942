import React from "react";
import { Link } from "gatsby";
import Switcher from "../utils/header/LanguageSwitcher";
import { LayoutContext } from "../layout";
import gsap from "gsap";
import NavButton from "../utils/NavButton";
import Logo from "../utils/header/Logo";

import "../../styles/components/blocks/Header.scss";
import eventBus from "../../utils/eventBus";

const Header = ({ pageContext, path, menuOpen }) => {
  const [bookingOpen, setBookingOpen] = React.useState(false);
  const [white, setWhite] = React.useState(false);
  const [v404, set404] = React.useState(false);
  const headerDOM = React.useRef();

  React.useEffect(() => {
    return function cleanUp() {
      setWhite(false);
    };
  }, []);

  return (
    <header ref={headerDOM} className={`bi_header ${menuOpen && "black"}`}>
      <div className="bi_header_content">
        <Link to={path.includes("/en") ? "/en" : "/"} aria-label="île Barbe">
          <Logo color={menuOpen ? "white" : "black"} />
        </Link>
        <div className="bi_nav_actions">
          <span
            className="bi_nav_item green"
            onClick={() => setBookingOpen(!bookingOpen)}
          >
            <a
              href="https://booking.roomcloud.net/be/se2/hotel.jsp?hotel=17608"
              target="_blank"
              className="bi_nav_book"
            >
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.79915e-07 4.50505L3.24068e-07 3.49495L6.06061 3.49495L3.28283 0.717172L4 -1.74846e-07L8 4L4 8L3.28283 7.28283L6.06061 4.50505L2.79915e-07 4.50505Z"
                  fill="white"
                />
              </svg>
              <span>{path.includes("/en") ? "Booking" : "Réservation"}</span>
            </a>
          </span>
          <span className="bi_nav_item">
            <a
              href="https://www.capcadeau.com/les-suites-de-lile-barbe"
              target="_blank"
            >
              <svg
                className="gift"
                width="24"
                height="23"
                viewBox="0 0 24 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.8 18.2487V21.1842H22.2V18.2487H1.8ZM1.8 4.84211H5.55C5.45 4.66053 5.37 4.43355 5.31 4.16118C5.25 3.88882 5.22 3.64167 5.22 3.41974C5.22 2.47149 5.55 1.66447 6.21 0.998684C6.87 0.332895 7.66 0 8.58 0C9.2 0 9.77 0.15636 10.29 0.469079C10.81 0.781798 11.22 1.18026 11.52 1.66447L12.03 2.45132L12.51 1.66447C12.83 1.16009 13.24 0.756579 13.74 0.453947C14.24 0.151316 14.78 0 15.36 0C16.32 0 17.13 0.327851 17.79 0.983553C18.45 1.63925 18.78 2.45132 18.78 3.41974C18.78 3.64167 18.755 3.8636 18.705 4.08553C18.655 4.30746 18.58 4.55965 18.48 4.84211H22.2C22.68 4.84211 23.1 5.02368 23.46 5.38684C23.82 5.75 24 6.17368 24 6.6579V21.1842C24 21.6684 23.82 22.0921 23.46 22.4553C23.1 22.8184 22.68 23 22.2 23H1.8C1.32 23 0.9 22.8184 0.54 22.4553C0.18 22.0921 0 21.6684 0 21.1842V6.6579C0 6.17368 0.18 5.75 0.54 5.38684C0.9 5.02368 1.32 4.84211 1.8 4.84211ZM1.8 15.0105H22.2V6.6579H14.73L17.73 10.8645L16.29 11.9539L12 5.96184L7.71 11.9539L6.27 10.8645L9.27 6.6579H1.8V15.0105ZM8.61 5.02368C9.05 5.02368 9.425 4.86732 9.735 4.55461C10.045 4.24189 10.2 3.8636 10.2 3.41974C10.2 2.97588 10.045 2.59759 9.735 2.28487C9.425 1.97215 9.05 1.81579 8.61 1.81579C8.17 1.81579 7.795 1.97215 7.485 2.28487C7.175 2.59759 7.02 2.97588 7.02 3.41974C7.02 3.8636 7.175 4.24189 7.485 4.55461C7.795 4.86732 8.17 5.02368 8.61 5.02368ZM15.36 5.02368C15.82 5.02368 16.205 4.86732 16.515 4.55461C16.825 4.24189 16.98 3.8636 16.98 3.41974C16.98 2.97588 16.825 2.59759 16.515 2.28487C16.205 1.97215 15.82 1.81579 15.36 1.81579C14.94 1.81579 14.575 1.97215 14.265 2.28487C13.955 2.59759 13.8 2.97588 13.8 3.41974C13.8 3.8636 13.955 4.24189 14.265 4.55461C14.575 4.86732 14.94 5.02368 15.36 5.02368Z"
                  fill={menuOpen ? "#FFFFFF" : "#3A3636"}
                />
              </svg>
            </a>
          </span>
          <span
            className={
              "bi_nav_item" +
              (path === "/a_propos" ||
              path === "/a_propos/" ||
              path === "/en/a_propos" ||
              path === "/en/a_propos/" ||
              v404
                ? " white"
                : "")
            }
          >
            <Switcher className="bi_nav_item" path={path} />
          </span>

          <LayoutContext.Consumer>
            {({ menuOpen, setMenuOpen }) => {
              return (
                <span
                  className={
                    (menuOpen ? "bi_nav_item white" : "bi_nav_item") +
                    " bi_nav_menu"
                  }
                  onClick={() =>
                    menuOpen
                      ? eventBus.dispatch("closeMenuAnimation")
                      : setMenuOpen(true)
                  }
                >
                  <span className="bi_nav_label">
                    {!menuOpen
                      ? "Menu"
                      : path.includes("/en")
                      ? "Close"
                      : "Fermer"}
                  </span>
                  <NavButton
                    className={
                      "bi_nav_item" +
                      (path === "/a_propos" ||
                        path === "/a_propos/" ||
                        path === "/en/a_propos" ||
                        path === "/en/a_propos/")
                        ? "white"
                        : ""
                    }
                    type="nav"
                    state={false}
                  ></NavButton>
                </span>
              );
            }}
          </LayoutContext.Consumer>
        </div>
      </div>
    </header>
  );
};

export default Header;
